.Collapsible {
  border: 1px solid var(--color-content-border);
  color: var(--color-font);
  margin-bottom: 5px;
}

.Collapsible__trigger {
  padding: 10px;
  color: var(--color-font);
  cursor: pointer;
}

.Collapsible__trigger.is-open {
  padding: 10px;

}

.Collapsible__contentInner {
  padding: 10px;
}
