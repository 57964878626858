:root {
  --base-background: #FFF;
  --color-content: #25AAE2;
  --color-content-light: #8DD3E3;
  --color-dark: #06266F;
  --color-light-accent: #FFD073;
  --color-accent: #FFAA00;

  --color-border: #CCC;
  --color-border--active: #000;
  --color-content-border: #4671D5;

  --color-font: #000;
  --color-font-light: #FFF;

  --max-width: 100%;
  --min-width: 100%;

  --color-link: #FFBF40;

  --success-color: #DFF0D8;
  --danger-color: #F2DEDE;
  --warn-color: #FCF8E3;
}
