.react-tabs {
   color: white;
}

.react-tabs .react-tabs__tab-list {
  background-color: var(--color-content-light) !important;
}

.react-tabs .react-tabs__tab-panel {
  color: var(--color-font);
}
