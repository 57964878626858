* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

/* fonts
-----------------------------------------------------------------------------*/
.monospace {
  font-family: monospace;
  font-size: 16px;
}

/* titles
-----------------------------------------------------------------------------*/

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.link {
  text-decoration: underline;
}

.link:hover {
  text-decoration: none;
}

:focus,
:active {
  outline: none;
}

li {
  list-style: none;
  position: relative;
}

.center {
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.h1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: bold;
}

hr {
  height: 1px;
  border: 0;
  background: rgba(137, 151, 167, 0.1);
}

main,
header,
footer,
section,
article,
aside,
time {
  display: block;
  position: relative;
}

/* button
-----------------------------------------------------------------------------*/
.button--wrapper {
  margin-top: 10px;
  display:flex;
  justify-content: flex-end;
}

/* modal
-----------------------------------------------------------------------------*/
.ReactModal__Overlay {
  z-index: 9999;
}

.modal-wrapper {
  display: flex;
	flex-direction: column;
	min-height: 100%;
}

.modal-content {
  flex: 1 0 auto;
}

.modal-footer {
  flex: 0 0 auto;
}

/* flex
-----------------------------------------------------------------------------*/
.flexbox {
  display: flex;
}

.flexbox--justify-between {
  justify-content: space-between;
}
.flexbox--align-center {
  align-items: center;
}

/* pre
-----------------------------------------------------------------------------*/
pre {
  white-space: pre-wrap;
}

/* table
-----------------------------------------------------------------------------*/
.row {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  padding: 5px;
}

.column {
  padding: 10px;
  flex: 50%;
  min-width: 50%;
}

.name, .value {
  display: flex;
  align-items: center;
  padding: 5px;
}

.name {
  flex: 30%;
  min-width: 30%;
}

.value {
  justify-content: flex-end;
  flex: 70%;
  overflow: auto;
}

/* margin
-----------------------------------------------------------------------------*/
.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

/* width
-----------------------------------------------------------------------------*/
.mh500 {
  max-height: 500px;
}

/* width
-----------------------------------------------------------------------------*/
.w50p {
  width: 50%;
}

.w100p {
  width: 100%;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.hidden {
  overflow: hidden;
}

.hidden-visible {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
  overflow: hidden;
}

/* input
-----------------------------------------------------------------------------*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

input[type="button"], input[type="submit"] {
  background-color: var(--color-content);
  color: var(--color-font-light);
  padding: 14px 20px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
}

input[type=text], input[type=password], input[type=number] {
  font-size: 1em;
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type=checkbox] {
  zoom: 2;
}

input[type=text], input[type=password], input[type=number] {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

input[disabled] {
  opacity: 50%;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

main {
  overflow: hidden;
}

/* image
-----------------------------------------------------------------------------*/
svg {
  color: var(--color-content);
}

/* messages
-----------------------------------------------------------------------------*/
input.invalid {
  border: 1px solid red;
}

.error-block {
  color: red;
  font-size: .8em;
  margin-top: 5px;
}
